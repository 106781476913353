import React, { useEffect, useState } from 'react';
import { Loader, Stack, TextInput, Table, Text, Checkbox } from '@mantine/core';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { useDebouncedValue } from '@mantine/hooks';
import { ProductListFragment } from '../../graphql/graphql';
import { useProductService } from '../../services/ProductService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type SearchProductProps = {
	allowMultiple?: boolean;
	onSelected: (products: ProductListFragment[]) => void;
	excludeIds?: string[];
};

export default function SearchProduct(props: SearchProductProps) {
	const { onSelected, allowMultiple, excludeIds } = props;

	const productService = useProductService();

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [products, setProducts] = useState<ProductListFragment[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<ProductListFragment[]>([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

	useEffect(() => {
		if (debouncedSearchQuery) {
			const loader = addLoader();
			const request = productService.searchByName(debouncedSearchQuery, excludeIds);
			setSelectedProducts([]);
			request.response
				.then((products) => {
					setProducts(products);
				})
				.catch((err) => {
					NotificationUtils.showError(err as Error, 'Failed to search products');
				})
				.finally(() => {
					removeLoader(loader);
				});
		}
	}, [addLoader, productService, debouncedSearchQuery, removeLoader, excludeIds]);

	const handleProductSelected = (event: React.ChangeEvent<HTMLInputElement>, product: ProductListFragment) => {
		const index = selectedProducts.findIndex((p) => p.id === product.id);

		let updatedProducts: ProductListFragment[] = [];

		if (allowMultiple) {
			if (event.currentTarget.checked && index === -1) {
				updatedProducts = [...selectedProducts, product];
			} else if (!event.currentTarget.checked && index >= 0) {
				updatedProducts = [...selectedProducts.slice(0, index), ...selectedProducts.slice(index + 1)];
			}
		} else {
			if (event.currentTarget.checked) {
				updatedProducts = [product];
			} else {
				updatedProducts = [];
			}
		}

		setSelectedProducts(updatedProducts);
		onSelected(updatedProducts);
	};

	return (
		<Stack>
			<TextInput
				value={searchQuery}
				onChange={(event) => setSearchQuery(event.currentTarget.value)}
				label="Search products by name"
				disabled={isLoading}
			/>
			{isLoading && (
				<Text ta="center">
					<Loader size="xs" ml="xs" />
				</Text>
			)}
			{!isLoading &&
				debouncedSearchQuery &&
				(products.length ? (
					<Table>
						<tbody>
							{products.map((a) => (
								<tr key={a.id}>
									<td width="100%">{a.name}</td>
									<td align="right">
										<Checkbox
											checked={selectedProducts.findIndex((p) => p.id === a.id) >= 0}
											onChange={(e) => handleProductSelected(e, a)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				) : (
					<Text ta="center">No search results</Text>
				))}
		</Stack>
	);
}
