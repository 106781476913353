import { useMemo } from 'react';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import {
	PodcastCreateInput,
	PodcastListFragment,
	PodcastQuery,
	PodcastQueryVariables,
	PodcastSingleFragment,
	PodcastUpdateInput,
	PodcastsQuery,
	CreatePodcastMutation,
	CreatePodcastMutationVariables,
	DeletePodcastsMutation,
	DeletePodcastsMutationVariables,
	UpdatePodcastMutation,
	UpdatePodcastMutationVariables,
	PodcastUsersQuery,
	PodcastUserFragment,
	ProductPodcastsQueryVariables,
	PodcastUsersQueryVariables,
	ProductPodcastsQuery,
	ProductPodcastFragment,
	DeleteProductPodcastsMutation,
	DeleteProductPodcastsMutationVariables,
	CreateProductPodcastMutation,
	CreateProductPodcastMutationVariables,
	ProductPodcastCreateInput,
} from '../graphql/graphql';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';

export const GQL_FRAG_PODCAST_LIST = gql`
	fragment PodcastList on Podcast {
		id
		title
		isManaged
		captivateId
		captivateFeedUrl
	}
`;

export const GQL_FRAG_PODCAST_SINGLE = gql`
	fragment PodcastSingle on Podcast {
		id
		title
		isManaged
		captivateId
		captivateFeedUrl
	}
`;

export const GQL_FRAG_PODCAST_USER = gql`
	fragment PodcastUser on PodcastUser {
		id
		captivateFeedUrl
		userId
		podcastId
		user {
			fullName
		}
		podcast {
			title
			captivateFeedUrl
		}
	}
`;

export const GQL_FRAG_PRODUCT_PODCAST = gql`
	fragment ProductPodcast on ProductPodcast {
		id
		productId
		podcastId
		product {
			name
		}
		podcast {
			title
			captivateFeedUrl
		}
	}
`;

export const GQL_GET_PODCAST = gql`
	${GQL_FRAG_PODCAST_SINGLE}
	query Podcast($podcastId: String!) {
		podcast(where: { id: $podcastId }) {
			...PodcastSingle
		}
	}
`;

export const GQL_GET_PODCASTS_FOR_LIST = gql`
	${GQL_FRAG_PODCAST_LIST}
	query Podcasts {
		podcasts {
			...PodcastList
		}
	}
`;

export const GQL_GET_PODCAST_USERS = gql`
	${GQL_FRAG_PODCAST_USER}
	query PodcastUsers($podcastId: String!) {
		podcastUsers(where: { podcastId: { equals: $podcastId } }) {
			...PodcastUser
		}
	}
`;

export const GQL_GET_PRODUCT_PODCASTS = gql`
	${GQL_FRAG_PRODUCT_PODCAST}
	query ProductPodcasts($podcastId: String!) {
		productPodcasts(where: { podcastId: { equals: $podcastId } }) {
			...ProductPodcast
		}
	}
`;

export const GQL_DELETE_PODCASTS = gql`
	mutation DeletePodcasts($podcastIds: [String!]) {
		deleteManyPodcast(where: { id: { in: $podcastIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_PODCAST = gql`
	${GQL_FRAG_PODCAST_SINGLE}
	mutation CreatePodcast($podcast: PodcastCreateInput!) {
		createOnePodcast(data: $podcast) {
			...PodcastSingle
		}
	}
`;

export const GQL_DELETE_PRODUCT_PODCASTS = gql`
	mutation DeleteProductPodcasts($productPodcastIds: [String!]) {
		deleteManyProductPodcast(where: { id: { in: $productPodcastIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_PRODUCT_PODCAST = gql`
	${GQL_FRAG_PRODUCT_PODCAST}
	mutation CreateProductPodcast($productPodcast: ProductPodcastCreateInput!) {
		createOneProductPodcast(data: $productPodcast) {
			...ProductPodcast
		}
	}
`;

export const GQL_UPDATE_PODCAST = gql`
	${GQL_FRAG_PODCAST_SINGLE}
	mutation UpdatePodcast($podcastId: String!, $podcast: PodcastUpdateInput!) {
		updateOnePodcast(where: { id: $podcastId }, data: $podcast) {
			...PodcastSingle
		}
	}
`;

export default class PodcastService implements ICrudService<PodcastListFragment, PodcastSingleFragment> {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public get(podcastId: string) {
		const request = ApolloUtils.abortableQuery<PodcastQuery, PodcastSingleFragment | null, PodcastQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_PODCAST,
				variables: {
					podcastId,
				},
			},
			(data) => data.podcast ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<PodcastsQuery, PodcastListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_PODCASTS_FOR_LIST,
			},
			(data) => data.podcasts,
			this._registerAbort,
		);

		return request;
	}

	public async create(podcast: PodcastCreateInput) {
		const result = await this._apolloClient.mutate<CreatePodcastMutation, CreatePodcastMutationVariables>({
			mutation: GQL_CREATE_PODCAST,
			variables: {
				podcast,
			},
		});
		if (!result.data?.createOnePodcast) {
			throw new Error('Failed to create podcast');
		}
		return result.data.createOnePodcast;
	}

	public async update(podcastId: string, podcast: PodcastUpdateInput) {
		const result = await this._apolloClient.mutate<UpdatePodcastMutation, UpdatePodcastMutationVariables>({
			mutation: GQL_UPDATE_PODCAST,
			variables: {
				podcastId,
				podcast,
			},
		});
		if (!result.data?.updateOnePodcast) {
			throw new Error('Failed to update podcast');
		}
		return result.data.updateOnePodcast;
	}

	public async delete(podcastIds: string | string[]) {
		podcastIds = Array.isArray(podcastIds) ? podcastIds : [podcastIds];
		await this._apolloClient.mutate<DeletePodcastsMutation, DeletePodcastsMutationVariables>({
			mutation: GQL_DELETE_PODCASTS,
			variables: {
				podcastIds,
			},
		});
	}

	public syncPodcasts() {
		return FetchUtils.postJson<{ success: boolean }>('/api/podcast/sync/all', {});
	}

	public getPodcastUsers(podcastId: string) {
		const request = ApolloUtils.abortableQuery<
			PodcastUsersQuery,
			PodcastUserFragment[],
			PodcastUsersQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_PODCAST_USERS,
				variables: {
					podcastId,
				},
			},
			(data) => data.podcastUsers,
			this._registerAbort,
		);
		return request;
	}

	public getProductPodcasts(podcastId: string) {
		const request = ApolloUtils.abortableQuery<
			ProductPodcastsQuery,
			ProductPodcastFragment[],
			ProductPodcastsQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_PRODUCT_PODCASTS,
				variables: {
					podcastId,
				},
			},
			(data) => data.productPodcasts,
			this._registerAbort,
		);
		return request;
	}

	public async addProductPodcast(productPodcast: ProductPodcastCreateInput) {
		const result = await this._apolloClient.mutate<
			CreateProductPodcastMutation,
			CreateProductPodcastMutationVariables
		>({
			mutation: GQL_CREATE_PRODUCT_PODCAST,
			variables: {
				productPodcast,
			},
		});
		if (!result.data?.createOneProductPodcast) {
			throw new Error('Failed to create product podcast');
		}
		return result.data.createOneProductPodcast;
	}

	public async removeProductPodcast(productPodcastIds: string | string[]) {
		productPodcastIds = Array.isArray(productPodcastIds) ? productPodcastIds : [productPodcastIds];
		await this._apolloClient.mutate<DeleteProductPodcastsMutation, DeleteProductPodcastsMutationVariables>({
			mutation: GQL_DELETE_PRODUCT_PODCASTS,
			variables: {
				productPodcastIds,
			},
		});
	}
}

export function usePodcastService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new PodcastService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
