import React, { useCallback } from 'react';
import CrudTable, { CrudTableColumn, useCrudState } from '../crud/CrudTable';
import { usePodcastService } from '../../services/PodcastService';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { PodcastListFragment, PodcastSingleFragment } from '../../graphql/graphql';
import { Flex, Button, Tooltip } from '@mantine/core';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { Link } from 'react-router-dom';
import StatusBadge from '../content/StatusBadge';
import { modals } from '@mantine/modals';
import ConfirmModal from '../modals/ConfirmModal';

export default function PodcastsList() {
	const { isLoading, addLoader, removeLoader } = useLoadTracker();

	const podcastService = usePodcastService();

	const fetchPodcasts = useCallback((): AbortableRequest<PodcastSingleFragment[]> | null => {
		return podcastService.getAll();
	}, [podcastService]);

	const crudState = useCrudState<PodcastListFragment, PodcastSingleFragment>(fetchPodcasts);

	const handleToggleManaged = (podcast: PodcastListFragment) => {
		const modalId = 'manage-podcast';
		if (podcast.isManaged) {
			modals.open({
				modalId,
				title: 'Un-manage podcast',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-manage"
						onConfirm={async () => {
							const updatedPodcast = await podcastService.update(podcast.id, {
								isManaged: {
									set: false,
								},
							});
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedPodcast.id ? updatedPodcast : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-manage the podcast {podcast.title}?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Manage podcast',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Manage"
						onConfirm={async () => {
							const updatedPodcast = await podcastService.update(podcast.id, {
								isManaged: {
									set: true,
								},
							});
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedPodcast.id ? updatedPodcast : item,
								),
							);
						}}
					>
						Are you sure you want to manage the podcast {podcast.title}?
					</ConfirmModal>
				),
			});
		}
	};

	const handleSyncClicked = async () => {
		const loader = addLoader();
		try {
			const request = podcastService.syncPodcasts();
			await request.response;
			crudState.refetch();
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to sync podcasts');
		} finally {
			removeLoader(loader);
		}
	};

	const crudColumns = (podcast: PodcastListFragment): CrudTableColumn[] => [
		{
			children: podcast.title,
		},
		{
			children: (
				<StatusBadge
					status={podcast.isManaged ? 'managed' : 'unmanaged'}
					onClick={() => handleToggleManaged(podcast)}
				/>
			),
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/podcasts/${podcast.id}`}>
						{podcast.isManaged ? (
							<Button variant="subtle">View</Button>
						) : (
							<Tooltip label={podcast.isManaged ? undefined : 'Must be managed to view'}>
								<Button variant="subtle" disabled>
									View
								</Button>
							</Tooltip>
						)}
					</Link>
				</Flex>
			),
		},
	];

	return (
		<CrudTable
			items={crudState.items}
			isLoading={crudState.isFetching || isLoading}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="podcast"
			onAddClicked={handleSyncClicked}
			addButtonText="Sync with Captivate"
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Title',
	},
	{
		children: '',
	},
];
