import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import PodcastsList from '../../components/podcasts/PodcastsList';

export default function PodcastsPage() {
	return (
		<BackendPage title="Podcasts">
			<PodcastsList />
		</BackendPage>
	);
}
