import { useCallback, useState } from 'react';

export default function usePaginationState() {
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const handlePageChanged = useCallback(
		(page: number) => {
			if (page >= 1 && page <= totalPages) {
				setCurrentPage(page);
				//props.onChange(page);
			}
		},
		[totalPages],
	);

	return {
		currentPage,
		totalPages,
		setCurrentPage,
		setTotalPages,
		handlePageChanged,
	};
}
