import React, { useState } from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { ProductPodcastFragment, ProductListFragment } from '../../graphql/graphql';
import SearchProduct, { SearchProductProps } from '../products/SearchProduct';
import { usePodcastService } from '../../services/PodcastService';

export type AddProductPodcastModalProps = CrudAddModalProps<ProductPodcastFragment, ProductPodcastFragment> & {
	podcastId: string;
};

export default function AddProductPodcastModal(props: AddProductPodcastModalProps) {
	const { modalId, podcastId, onCancel, onCreated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const podcastService = usePodcastService();

	const [selectedProduct, setSelectedProduct] = useState<ProductListFragment | null>(null);

	const handleProductSelected: SearchProductProps['onSelected'] = (products) => {
		if (products.length) {
			setSelectedProduct(products[0]);
		} else {
			setSelectedProduct(null);
		}
	};

	const handleAddProduct = async () => {
		if (!selectedProduct) {
			return;
		}
		const loader = addLoader();
		try {
			const created = await podcastService.addProductPodcast({
				podcast: {
					connect: {
						id: podcastId,
					},
				},
				product: {
					connect: {
						id: selectedProduct.id,
					},
				},
			});
			onCreated?.(created);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to add product to podcast');
		} finally {
			removeLoader(loader);
			modals.close(modalId);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<Stack>
				<SearchProduct onSelected={handleProductSelected} />
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button type="submit" disabled={isLoading || !selectedProduct} onClick={handleAddProduct}>
						Assign product
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}
