import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import EditPodcast from '../../components/podcasts/EditPodcast';
import NotFound from '../../components/page/NotFound';
import { useParams } from 'react-router-dom';

export default function PodcastPage() {
	const { podcastId } = useParams<{ podcastId: string }>();

	return <BackendPage>{podcastId ? <EditPodcast podcastId={podcastId} /> : <NotFound />}</BackendPage>;
}
