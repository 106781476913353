import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import ConfirmModal from '../modals/ConfirmModal';
import { ResourceListFragment, ResourceSingleFragment } from '../../graphql/graphql';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { ActionIcon, Button, Flex, Menu, Tabs } from '@mantine/core';
import { modals, openModal } from '@mantine/modals';
import {
	IconDotsVertical,
	IconFileDownload,
	IconLink,
	IconLinkOff,
	IconList,
	IconPencil,
	IconTrash,
} from '@tabler/icons-react';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AddResourceModal from './AddResourceModal';
import EditResourceModal from './EditResourceModal';
import { useResourceService } from '../../services/ResourceService';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import ResourceUsageModal from '../resources/ResourceUsageModal';
import StatusBadge from '../content/StatusBadge';
import ResourceTypeList from './ResourceTypeList';

export default function ResourcesList() {
	const [filterType, setFilterType] = useState<FilterType>('all');
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');
	const [selectedTab, setSelectedTab] = useState<string>('all');

	const navigate = useNavigate();
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const resourceService = useResourceService();

	const fetchResources = useCallback((): AbortableRequest<ResourceListFragment[]> | null => {
		if (filterType === 'orphans') {
			return resourceService.getOrphans();
		} else if (searchQuery) {
			return resourceService.searchByTitle(searchQuery);
		} else {
			return resourceService.getAll();
		}
	}, [filterType, searchQuery, resourceService]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<ResourceListFragment, ResourceSingleFragment>(fetchResources);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'resource',
		nameAccessor: (resource) => resource.title,
		addModalFactory: (props) => <AddResourceModal {...props} />,
		editModalFactory: (props) => <EditResourceModal {...props} />,
		delete: (resource) => resourceService.delete(resource.id),
	});

	const handleOnNavigateToProgramme = (programmeId: string) => {
		navigate(`/programmes/${programmeId}`);
	};

	const handleUnlinkResourceFromCategory = async (resourceId: string, categoryId: string) => {
		const loader = addLoader();
		try {
			await resourceService.removeResourceFromCategory(resourceId, categoryId);
			removeLoader(loader);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to unlink resource from category');
			removeLoader(loader);
		}
	};

	const handleUsageClicked = async (resource: ResourceListFragment) => {
		const modalId = `resource-usage-${resource.id}`;
		openModal({
			modalId,
			title: 'Resource usage',
			children: (
				<ResourceUsageModal
					modalId={modalId}
					resourceId={resource.id}
					onNavigate={handleOnNavigateToProgramme}
					onUnlink={handleUnlinkResourceFromCategory}
				/>
			),
		});
	};

	const crudColumns = (resource: ResourceListFragment): CrudTableColumn[] => [
		{
			children: resource.title,
		},
		{
			children: resource.slug,
		},
		{
			children: (
				<StatusBadge
					status={resource.isPublished ? 'published' : 'draft'}
					onClick={() => handleTogglePublished(resource)}
				/>
			),
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={resource.url} target="_blank" rel="noopener noreferrer">
						<Button variant="subtle">View</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(resource)}>
								Edit
							</Menu.Item>
							<Menu.Item leftSection={<IconLink />} onClick={() => handleUsageClicked(resource)}>
								Show usage
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(resource)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	const handleTogglePublished = (resource: ResourceListFragment) => {
		const modalId = 'publish-resource';
		if (resource.isPublished) {
			modals.open({
				modalId,
				title: 'Un-publish resource',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-publish"
						onConfirm={async () => {
							const updatedResource = await resourceService.publish(resource.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedResource.id ? updatedResource : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-publish the resource {resource.title}?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Publish resource',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Publish"
						onConfirm={async () => {
							const updatedResource = await resourceService.unpublish(resource.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedResource.id ? updatedResource : item,
								),
							);
						}}
					>
						Are you sure you want to publish the resource {resource.title}?
					</ConfirmModal>
				),
			});
		}
	};

	const handleTabChanged = (tab: string | null) => {
		if (!tab) {
			return;
		}

		setSelectedTab(tab);

		if (tab !== 'types') {
			setFilterType(tab === 'orphans' ? 'orphans' : 'all');
		}
	};

	return (
		<Tabs value={selectedTab} onChange={handleTabChanged}>
			<Tabs.List mb="md">
				<Tabs.Tab value="all" leftSection={<IconFileDownload />}>
					All resources
				</Tabs.Tab>
				<Tabs.Tab value="orphans" leftSection={<IconLinkOff />}>
					Orphan resources
				</Tabs.Tab>
				<Tabs.Tab value="types" leftSection={<IconList />}>
					Resource types
				</Tabs.Tab>
			</Tabs.List>
			{selectedTab === 'types' ? (
				<ResourceTypeList />
			) : (
				<CrudTable
					items={crudState.items}
					isLoading={crudState.isFetching || isLoading}
					headers={crudHeaders}
					columns={crudColumns}
					entityName="resource"
					onAddClicked={crudHandlers.addHandler}
					search={{
						onSearchRequested: handleSearchRequested,
						onSearchCleared: handleSearchRequested,
						initialValue: searchQuery,
					}}
				/>
			)}
		</Tabs>
	);
}

type FilterType = 'all' | 'orphans';

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Title',
	},
	{
		children: 'Slug',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];
