import { TemplateType } from '../graphql/graphql';

export type TemplateTypeInfo = {
	id: Lowercase<TemplateType>;
	name: string;
	templateFormat: 'plaintext' | 'markdown' | 'html';
	perProductEnabled: boolean;
};

export const templateTypeMetadata: Record<TemplateType, TemplateTypeInfo> = {
	[TemplateType.UserRequestReset]: {
		id: 'user_request_reset',
		name: 'Password Reset Request Email',
		templateFormat: 'markdown',
		perProductEnabled: false,
	},
	[TemplateType.InvoicePdf]: {
		id: 'invoice_pdf',
		name: 'Invoice PDF',
		templateFormat: 'html',
		perProductEnabled: false,
	},
	[TemplateType.InvoiceEmail]: {
		id: 'invoice_email',
		name: 'Invoice Email',
		templateFormat: 'markdown',
		perProductEnabled: false,
	},
	[TemplateType.SubscriptionExpiringSoonEmail]: {
		id: 'subscription_expiring_soon_email',
		name: 'Subscription Expiring Soon Email',
		templateFormat: 'markdown',
		perProductEnabled: true,
	},
	[TemplateType.SubscriptionExpiredEmail]: {
		id: 'subscription_expired_email',
		name: 'Subscription Expired Email',
		templateFormat: 'markdown',
		perProductEnabled: true,
	},
	[TemplateType.SubscriptionRenewedEmail]: {
		id: 'subscription_renewed_email',
		name: 'Subscription Renewed Email',
		templateFormat: 'markdown',
		perProductEnabled: true,
	},
	[TemplateType.SubscriptionRenewalFailedEmail]: {
		id: 'subscription_renewal_failed_email',
		name: 'Subscription Renewal Failed Email',
		templateFormat: 'markdown',
		perProductEnabled: true,
	},
	[TemplateType.SubscriptionCancelledEmail]: {
		id: 'subscription_cancelled_email',
		name: 'Subscription Cancelled Email',
		templateFormat: 'markdown',
		perProductEnabled: true,
	},
	[TemplateType.EventRegistrationConfirmationEmail]: {
		id: 'event_registration_confirmation_email',
		name: 'Event Registration Confirmation Email',
		templateFormat: 'markdown',
		perProductEnabled: false,
	},
	[TemplateType.EventRegistrationCancelledEmail]: {
		id: 'event_registration_cancelled_email',
		name: 'Event Registration Cancelled Email',
		templateFormat: 'markdown',
		perProductEnabled: false,
	},
	[TemplateType.ContactForm]: {
		id: 'contact_form',
		name: 'Contact Form',
		templateFormat: 'html',
		perProductEnabled: false,
	},
	[TemplateType.WelcomeEmail]: {
		id: 'welcome_email',
		name: 'Welcome Email',
		templateFormat: 'markdown',
		perProductEnabled: true,
	},
};
