import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { useMemo } from 'react';
import {
	PaymentListFragment,
	PaymentsPaginatedQuery,
	PaymentsQuery,
	SearchPaymentsQuery,
	SearchPaymentsQueryVariables,
} from '../graphql/graphql';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';
import { PaginatedResponse } from '@dr-pam/common-components/Utils/PaginationUtils';

export const GQL_FRAG_PAYMENT_LIST = gql`
	fragment PaymentList on Payment {
		id
		created
		modified
		amountInCents
		taxInCents
		couponDiscountAppliedInCents
		coupon {
			code
			discountType
			percentage
			fixedAmountInCents
		}
		currency
		billingAddress
		billingCountryCode
		isInvoiceSent
		user {
			id
			email
			displayName
		}
		paymentItems {
			productSubscriptionType {
				id
				name
				product {
					id
					name
					shortName
				}
			}
		}
		stripePaymentIntents(where: { status: { equals: SUCCEEDED } }) {
			paymentIntentId
			chargedInCents
			feesPaidInCents
			taxPaidInCents
			netInCents
		}
	}
`;

export const GQL_GET_PAYMENTS_FOR_LIST = gql`
	${GQL_FRAG_PAYMENT_LIST}
	query Payments {
		payments(
			orderBy: { modified: desc }
			where: { stripePaymentIntents: { some: { status: { equals: SUCCEEDED }, chargedInCents: { gt: 0 } } } }
		) {
			...PaymentList
		}
	}
`;

export const GQL_GET_PAYMENTS_FOR_LIST_PAGINATED = gql`
	${GQL_FRAG_PAYMENT_LIST}
	query PaymentsPaginated($skip: Int, $take: Int) {
		payments(
			orderBy: { modified: desc }
			where: { stripePaymentIntents: { some: { status: { equals: SUCCEEDED }, chargedInCents: { gt: 0 } } } }
			skip: $skip
			take: $take
		) {
			...PaymentList
		}
		aggregatePayment {
			_count {
				_all
			}
		}
	}
`;

export const GQL_SEARCH_PAYMENTS_FOR_LIST = gql`
	${GQL_FRAG_PAYMENT_LIST}
	query SearchPayments($query: String!, $excludeIds: [String!]) {
		payments(
			orderBy: { modified: desc }
			where: {
				id: { notIn: $excludeIds }
				stripePaymentIntents: { some: { status: { equals: SUCCEEDED }, chargedInCents: { gt: 0 } } }
				OR: [
					{ user: { is: { OR: [{ email: { contains: $query } }, { displayName: { contains: $query } }] } } }
					{
						paymentItems: {
							some: {
								productSubscriptionType: {
									is: {
										product: {
											is: {
												OR: [
													{ name: { contains: $query } }
													{ shortName: { contains: $query } }
												]
											}
										}
									}
								}
							}
						}
					}
				]
			}
		) {
			...PaymentList
		}
	}
`;

export default class PaymentService {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public getAll() {
		const request = ApolloUtils.abortableQuery<PaymentsQuery, PaymentListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_PAYMENTS_FOR_LIST,
			},
			(data) => data.payments,
			this._registerAbort,
		);

		return request;
	}

	public getAllPaginated(page = 1, perPage = 20) {
		const request = ApolloUtils.abortableQuery<PaymentsPaginatedQuery, PaginatedResponse<PaymentListFragment>>(
			this._apolloClient,
			{
				query: GQL_GET_PAYMENTS_FOR_LIST_PAGINATED,
				variables: {
					skip: (page - 1) * perPage,
					take: perPage,
				},
			},
			(data) => ({
				items: data.payments,
				total: data.aggregatePayment._count?._all ?? 0,
				page,
				perPage,
			}),
			this._registerAbort,
		);

		return request;
	}

	public search(query: string, excludeIds?: string[]) {
		const request = ApolloUtils.abortableQuery<
			SearchPaymentsQuery,
			PaymentListFragment[],
			SearchPaymentsQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_SEARCH_PAYMENTS_FOR_LIST,
				variables: {
					query,
					excludeIds: excludeIds ?? [],
				},
			},
			(data) => data.payments,
			this._registerAbort,
		);

		return request;
	}

	public resendInvoice(id: string) {
		const request = FetchUtils.postJson<never>(`/api/payment/${id}/resend`, {});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}
}

export function usePaymentService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new PaymentService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
