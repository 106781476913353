import React, { useCallback, useState } from 'react';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import { useUserService } from '../../services/UserService';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { ActionIcon, Badge, BadgeProps, Button, Flex, Menu } from '@mantine/core';
import {
	IconDotsVertical,
	IconLock,
	IconPencil,
	IconTrash,
	IconUserCheck,
	IconUserOff,
	IconUserShare,
} from '@tabler/icons-react';
import { Link, useSearchParams } from 'react-router-dom';
import type { User } from '@dr-pam/common-types/database';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import ConfirmModal from '../modals/ConfirmModal';
import { modals } from '@mantine/modals';
import ResetPasswordModal from './ResetPasswordModal';
import styled from '@emotion/styled';
import usePaginationState from '../../hooks/usePaginationState';
import ImpersonateUserModal from './ImpersonateUserModal';

export default function UsersList() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');

	const userService = useUserService();

	const pagination = usePaginationState();
	const { currentPage, setTotalPages } = pagination;

	const fetchUsers = useCallback((): AbortableRequest<User[]> | null => {
		if (searchQuery) {
			const request = userService.search(searchQuery);
			return {
				abort: request.abort,
				response: request.response.then((response) => {
					setTotalPages(1);
					return response;
				}),
			};
		} else {
			const request = userService.getAllPaginated(currentPage);
			return {
				abort: request.abort,
				response: request.response.then((response) => {
					setTotalPages(Math.ceil(response.total / response.perPage));
					return response.items;
				}),
			};
		}
	}, [currentPage, searchQuery, setTotalPages, userService]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<User, User>(fetchUsers);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'user',
		nameAccessor: (user) => user.displayName || user.email || user.id,
		addModalFactory: (props) => <AddUserModal {...props} />,
		editModalFactory: (props) => <EditUserModal {...props} />,
		delete: (user) => userService.delete(user.id),
	});

	const handleToggleEnabled = (user: User) => {
		const modalId = 'user-toggle-enabled';
		if (user.isEnabled) {
			modals.open({
				modalId,
				title: 'Disable user',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Disable user"
						onConfirm={async () => {
							const request = userService.update(user.id, {
								fullName: user.fullName,
								isEnabled: false,
							});
							const updatedUser = await request.response;
							crudState.update(updatedUser);
						}}
						danger
					>
						Are you sure you want to disable the user &quot;{user.displayName ?? user.email}&quot;?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Enable user',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Enable user"
						onConfirm={async () => {
							const request = userService.update(user.id, {
								fullName: user.fullName,
								isEnabled: true,
							});
							const updatedUser = await request.response;
							crudState.update(updatedUser);
						}}
					>
						Are you sure you want to enable the user &quot;{user.displayName ?? user.email}&quot;?
					</ConfirmModal>
				),
			});
		}
	};

	const handleResetPassword = (user: User) => {
		const modalId = 'user-reset-password';
		modals.open({
			modalId,
			title: 'Reset password',
			children: (
				<ResetPasswordModal
					modalId={modalId}
					current={user}
					onCancel={() => modals.close(modalId)}
					onEdited={(updatedUser) => {
						crudState.update(updatedUser);
					}}
				/>
			),
		});
	};

	const handleImpersonate = (user: User) => {
		const modalId = 'impersonate-user';
		modals.open({
			modalId,
			title: `Impersonate ${user.fullName}`,
			children: <ImpersonateUserModal modalId={modalId} userId={user.id} />,
		});
	};

	const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

	const crudColumns = (user: User): CrudTableColumn[] => [
		{
			children: (
				<>
					{user.email} {user.role === 'ADMIN' ? <StyledBadge color="green">Admin</StyledBadge> : undefined}
				</>
			),
		},
		{
			children: user.fullName,
		},
		{
			children: user.displayName,
		},
		{
			children: user.billingCountryCode ? regionNames.of(user.billingCountryCode) : null,
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/users/${user.id}`}>
						<Button variant="subtle">View</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(user)}>
								Edit
							</Menu.Item>
							<Menu.Item leftSection={<IconLock />} onClick={() => handleResetPassword(user)}>
								Rest password
							</Menu.Item>
							<Menu.Item leftSection={<IconUserShare />} onClick={() => handleImpersonate(user)}>
								Impersonate User
							</Menu.Item>
							<Menu.Item
								leftSection={user.isEnabled ? <IconUserOff /> : <IconUserCheck />}
								onClick={() => handleToggleEnabled(user)}
								color={user.isEnabled ? 'yellow' : 'green'}
							>
								{user.isEnabled ? 'Disable' : 'Enable'}
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(user)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	return (
		<CrudTable
			items={crudState.items}
			isLoading={crudState.isFetching}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="user"
			onAddClicked={crudHandlers.addHandler}
			search={{
				onSearchRequested: handleSearchRequested,
				onSearchCleared: handleSearchRequested,
				initialValue: searchQuery,
			}}
			pagination={pagination}
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Email',
	},
	{
		children: 'Full Name',
	},
	{
		children: 'Display Name',
	},
	{
		children: 'Billing country',
	},
	{
		children: '',
	},
];

const StyledBadge = styled(Badge)<BadgeProps>`
	cursor: pointer;
`;
