import React from 'react';
import { Button, Container, Group, Loader, Radio, Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useRuntimeConfigProvider } from '@dr-pam/common-components/Config/RuntimeConfigProvider';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';

export type ImpersonationTarget = {
	name: string;
	url: string;
};

export type ImpersonationRuntimeConfig = {
	impersonationTargets?: ImpersonationTarget[];
};

export type ImpersonateUserModalProps = {
	className?: string;
	modalId: string;
	userId: string;
};

export default function ImpersonateUserModal(props: ImpersonateUserModalProps) {
	const { className, modalId, userId } = props;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();
	const runtimeConfig = useRuntimeConfigProvider<ImpersonationRuntimeConfig>();
	const registerAbort = useAbortRegistry();

	const [target, setTarget] = React.useState<ImpersonationTarget | null>(null);

	const handleTargetChanged = (value: string) => {
		setTarget(runtimeConfig.config?.impersonationTargets?.find((t) => t.url === value) ?? null);
	};

	const getImpersonationToken = async () => {
		const tokenRequest = FetchUtils.abortableRequest(
			FetchUtils.postJson<{ token: string }>(`/api/auth/token`, {}),
			registerAbort,
		);

		const tokenResponse = await tokenRequest.response;

		if (!tokenResponse.token) {
			throw new Error('Failed to get token');
		}
		return tokenResponse.token;
	};

	const handleImpersonate = async () => {
		if (!target) {
			return;
		}
		const loader = addLoader();
		try {
			const token = await getImpersonationToken();
			// Need to create a form and submit it so that the browser handles the cookies correctly
			const form = document.createElement('form');
			form.method = 'POST';
			form.action = target.url;
			form.style.display = 'none';
			form.innerHTML = `<input type="hidden" name="userId" value="${userId}" /><input type="hidden" name="token" value="${token}" />`;
			document.body.appendChild(form);
			form.submit();
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to impersonate user');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		modals.close(modalId);
	};

	return (
		<Container className={`ImpersonateUserModal ${className ?? ''}`}>
			<Stack>
				{runtimeConfig.isLoading ? (
					<Loader />
				) : runtimeConfig.config?.impersonationTargets ? (
					<Radio.Group
						value={target?.url}
						onChange={handleTargetChanged}
						label="Select the application you want to access on behalf of this user"
					>
						{runtimeConfig.config.impersonationTargets.map((target) => (
							<Radio key={target.url} value={target.url} label={target.name} m="16px 0" />
						))}
					</Radio.Group>
				) : (
					<p>Failed to load runtime config, or could not find any impersonation targets.</p>
				)}
				<Group justify="flex-end" mt="md">
					<Button
						type="button"
						variant="subtle"
						onClick={handleCancel}
						disabled={isLoading || runtimeConfig.isLoading}
					>
						Cancel
					</Button>
					<Button onClick={() => handleImpersonate()} disabled={isLoading || target === null}>
						Impersonate
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}
