import React, { useCallback } from 'react';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import { useCouponService } from '../../services/CouponService';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { CouponDiscountType, CouponListFragment, CouponSingleFragment } from '../../graphql/graphql';
import { Flex, Menu, ActionIcon, List, Tooltip, Text } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import AddCouponModal from './AddCouponModal';
import EditCouponModal from './EditCouponModal';
import StatusBadge from '../content/StatusBadge';
import { modals } from '@mantine/modals';
import ConfirmModal from '../modals/ConfirmModal';

export default function CouponsList() {
	const couponService = useCouponService();

	const fetchCoupons = useCallback((): AbortableRequest<CouponSingleFragment[]> | null => {
		return couponService.getAll();
	}, [couponService]);

	const crudState = useCrudState<CouponListFragment, CouponSingleFragment>(fetchCoupons);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'coupon',
		nameAccessor: (coupon) => coupon.code,
		addModalFactory: (props) => <AddCouponModal {...props} />,
		editModalFactory: (props) => <EditCouponModal {...props} />,
		delete: (coupon) => couponService.delete(coupon.id),
	});

	const handleTogglePublished = (coupon: CouponListFragment) => {
		const modalId = 'publish-coupon';
		if (coupon.isPublished) {
			modals.open({
				modalId,
				title: 'Un-publish coupon',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-publish"
						onConfirm={async () => {
							const updatedCoupon = await couponService.unpublish(coupon.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedCoupon.id ? updatedCoupon : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-publish the coupon {coupon.code}?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Publish coupon',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Publish"
						onConfirm={async () => {
							const updatedCoupon = await couponService.publish(coupon.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedCoupon.id ? updatedCoupon : item,
								),
							);
						}}
					>
						Are you sure you want to publish the coupon {coupon.code}?
					</ConfirmModal>
				),
			});
		}
	};

	const crudColumns = (coupon: CouponListFragment): CrudTableColumn[] => [
		{
			children: coupon.code,
		},
		{
			children: coupon.description,
		},
		{
			children:
				coupon.discountType === CouponDiscountType.Percentage
					? `${(coupon.percentage ?? 0) * 100}%`
					: `$${(coupon.fixedAmountInCents ?? 0) / 100}`,
		},
		{
			children: (
				<List fz="xs">
					{coupon.restrictedToProducts.map((x) => (
						<List.Item key={x.id}>
							<Tooltip label={x.product.name}>
								<Text fz="xs">{x.product.shortName}</Text>
							</Tooltip>
						</List.Item>
					))}
				</List>
			),
		},
		{
			children: (
				<StatusBadge
					status={coupon.isPublished ? 'published' : 'draft'}
					onClick={() => handleTogglePublished(coupon)}
				/>
			),
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(coupon)}>
								Edit
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(coupon)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	return (
		<CrudTable
			items={crudState.items}
			isLoading={crudState.isFetching}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="coupon"
			onAddClicked={crudHandlers.addHandler}
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Code',
	},
	{
		children: 'Description',
	},
	{
		children: 'Discount',
	},
	{
		children: 'Products',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];
