import React from 'react';
import CrudTable, { CrudTableColumn } from '../crud/CrudTable';
import { TemplateTypeInfo, templateTypeMetadata } from '../../utils/TemplateUtils';
import { Badge, Button } from '@mantine/core';
import { Link } from 'react-router-dom';

const headers: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: '',
	},
	{
		children: '',
	},
];

const columns = (info: TemplateTypeInfo): CrudTableColumn[] => [
	{
		children: info.name,
	},
	{
		children: info.perProductEnabled ? (
			<Badge color="green">Per Product</Badge>
		) : (
			<Badge color="gray">Global</Badge>
		),
	},
	{
		children: (
			<Link to={`/templates/${info.id}`}>
				<Button variant="subtle">View</Button>
			</Link>
		),
	},
];

// FIXME: For now we only support markdown templates
// but we should support html as well in the future
const items = Object.values(templateTypeMetadata).filter((x) => x.templateFormat === 'markdown');
items.sort((a, b) => a.name.localeCompare(b.name));

export default function TemplateTypesList() {
	return <CrudTable headers={headers} columns={columns} items={items} entityName="Template Type" />;
}
