import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import { useParams } from 'react-router-dom';
import EditTemplate from '../../components/templates/EditTemplate';
import { TemplateType } from '../../graphql/graphql';
import NotFound from '../../components/page/NotFound';

export default function TemplatePage() {
	const { templateTypeId, productId } = useParams<{ templateTypeId: string; productId: string }>();
	const templateType = templateTypeId?.toUpperCase() as TemplateType | undefined;

	return (
		<BackendPage centerChildren={templateType === undefined}>
			{templateType ? <EditTemplate templateType={templateType} productId={productId} /> : <NotFound />}
		</BackendPage>
	);
}
