import React, { useState } from 'react';
import { usePodcastService } from '../../services/PodcastService';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { ActionIcon, Flex, Group, LoadingOverlay, Menu, Tabs, Tooltip } from '@mantine/core';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import { PodcastSingleFragment, PodcastUserFragment, ProductPodcastFragment } from '../../graphql/graphql';
import { IconBooks, IconCopy, IconDotsVertical, IconTrash, IconUsers } from '@tabler/icons-react';
import Content from '../Content';
import NotFound from '../page/NotFound';
import AddProductPodcastModal from './AddProductPodcastModal';
import ClipboardUtils from '@dr-pam/common-components/Utils/ClipboardUtils';

export type EditPodcastProps = {
	className?: string;
	podcastId: string;
};

export default function EditPodcast(props: EditPodcastProps) {
	const { className, podcastId } = props;
	const [selectedTab, setSelectedTab] = useState<string>('products');

	const podcastService = usePodcastService();

	const [podcast, setPodcast] = useState<PodcastSingleFragment | null>(null);

	const usersCrudState = useCrudState<PodcastUserFragment, PodcastUserFragment>();
	const productsCrudState = useCrudState<ProductPodcastFragment, ProductPodcastFragment>();

	const productCrudHandlers = useStandardCrudHandlers<ProductPodcastFragment, ProductPodcastFragment>({
		crudState: productsCrudState,
		entityName: 'assigned product',
		nameAccessor: (pp) => pp.product.name,
		delete: (pp) => podcastService.removeProductPodcast(pp.id),
		addModalFactory: (props) => <AddProductPodcastModal {...props} podcastId={podcast?.id ?? ''} />,
	});

	const isLoadingPodcast = useLoadingEffect(async () => {
		try {
			const podcastRequest = podcastService.get(podcastId);
			const podcast = await podcastRequest.response;
			setPodcast(podcast);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load podcast.');
		}
	}, [podcastService, podcastId]);

	const isLoading = useLoadingEffect(async () => {
		try {
			if (selectedTab === 'products') {
				const productsRequest = podcastService.getProductPodcasts(podcastId);
				const productPodcasts = await productsRequest.response;
				productsCrudState.setItems(productPodcasts);
			} else if (selectedTab === 'users') {
				const usersRequest = podcastService.getPodcastUsers(podcastId);
				const podcastUsers = await usersRequest.response;
				usersCrudState.setItems(podcastUsers);
			}
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load podcast.');
		}
	}, [podcastService, podcastId, selectedTab]);

	if (isLoadingPodcast) {
		return <LoadingOverlay visible={isLoadingPodcast} overlayProps={{ blur: 2 }} />;
	}

	if (podcast === null) {
		return <NotFound />;
	}

	const productCrudColumns = (pp: ProductPodcastFragment): CrudTableColumn[] => [
		{
			children: pp.product.name,
		},
		{
			children: (
				<Menu shadow="md" width={200}>
					<Menu.Target>
						<Group justify="flex-end">
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Group>
					</Menu.Target>

					<Menu.Dropdown>
						<Menu.Item
							leftSection={<IconTrash />}
							onClick={() => productCrudHandlers.deleteHandler(pp)}
							color="red"
						>
							Remove
						</Menu.Item>
					</Menu.Dropdown>
				</Menu>
			),
		},
	];

	const userCrudColumns = (pu: PodcastUserFragment): CrudTableColumn[] => [
		{
			children: pu.user.fullName,
		},
		{
			children: <a href={pu.captivateFeedUrl}>{pu.captivateFeedUrl}</a>,
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Tooltip label="Copy link to clipboard">
						<ActionIcon
							onClick={() => ClipboardUtils.copyToClipboardWithNotification(pu.captivateFeedUrl)}
							variant="subtle"
						>
							<IconCopy />
						</ActionIcon>
					</Tooltip>
				</Flex>
			),
		},
	];

	const handleTabChanged = (tab: string | null) => {
		if (!tab) {
			return;
		}

		setSelectedTab(tab);
	};

	return (
		<Content
			className={`EditPodcast ${className ?? ''}`}
			breadcrumbs={{ '/podcasts': 'Podcasts', '#': podcast.title }}
			title={podcast.title}
			fillHeight
		>
			<Tabs value={selectedTab} onChange={handleTabChanged} className={className}>
				<Tabs.List mb="md">
					<Tabs.Tab value="products" leftSection={<IconBooks />}>
						Assigned Products
					</Tabs.Tab>
					<Tabs.Tab value="users" leftSection={<IconUsers />}>
						Current Users
					</Tabs.Tab>
				</Tabs.List>
				{selectedTab === 'products' && (
					<CrudTable
						isLoading={isLoading}
						items={productsCrudState.items}
						headers={productCrudHeaders}
						columns={productCrudColumns}
						title={{ children: 'Assigned Products' }}
						entityName="product"
						onAddClicked={productCrudHandlers.addHandler}
						addButtonText="Assign Product"
					/>
				)}
				{selectedTab === 'users' && (
					<CrudTable
						isLoading={isLoading}
						items={usersCrudState.items}
						headers={userCrudHeaders}
						columns={userCrudColumns}
						title={{ children: 'Current Users' }}
						entityName="current user"
					/>
				)}
			</Tabs>
		</Content>
	);
}

const productCrudHeaders: CrudTableColumn[] = [
	{
		children: 'Product',
	},
	{
		children: '',
	},
];

const userCrudHeaders: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: 'RSS Feed URL',
	},
	{
		children: '',
	},
];
