import React, { useEffect, useState } from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useComputedSlug from '../../hooks/useComputedSlug';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, FileButton, Group, Loader, Select, Stack, TextInput, Tooltip } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { IconFilePlus } from '@tabler/icons-react';
import StringUtils from '@dr-pam/common-components/Utils/StringUtils';
import { v4 as uuid } from 'uuid';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import {
	ResourceListFragment,
	ResourceSingleFragment,
	CategoryListFragment,
	ResourceTypeListFragment,
} from '../../graphql/graphql';
import { CategorySortableTreeItem, useCategoryService } from '../../services/CategoryService';
import { useResourceService } from '../../services/ResourceService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useS3ObjectStorageService } from '@dr-pam/common-components/Services/S3ObjectStorageService';
import z from 'zod';

export const validationSchema = z.object({
	title: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
	filename: z.string().nonempty().max(512),
});

export type AddResourceModalProps = CrudAddModalProps<ResourceListFragment, ResourceSingleFragment> & {
	categoryId?: string;
};

export default function AddResourceModal(props: AddResourceModalProps) {
	const { modalId, onCancel, onCreated, categoryId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [categories, setCategories] = useState<CategorySortableTreeItem<CategoryListFragment>[]>([]);
	const [resourceTypes, setResourceTypes] = useState<ResourceTypeListFragment[]>([]);
	const [fileToUpload, setFileToUpload] = useState<File | null>(null);

	const s3ObjectStorageService = useS3ObjectStorageService();
	const resourceService = useResourceService();
	const categoryService = useCategoryService();

	useEffect(() => {
		const loader = addLoader();
		const request = resourceService.getAllTypes();
		request.response
			.then((types) => {
				setResourceTypes(types);
			})
			.catch((err) => {
				NotificationUtils.showError(err as Error, 'Failed to load resource types');
			})
			.finally(() => {
				removeLoader(loader);
			});
	}, [addLoader, removeLoader, resourceService]);

	const isLoadingCategories = useLoadingEffect(async () => {
		try {
			const response = await categoryService.getAllInFlattenedTree().response;
			setCategories(response);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load categories');
		}
	}, [categoryService, categoryId]);

	const form = useForm<AddResourceForm>({
		initialValues: {
			title: '',
			slug: '',
			url: '',
			filename: '',
			categoryId: categoryId ?? '',
			resourceTypeId: undefined,
		},
		validate: zodResolver(validationSchema),
	});

	const handleFileSelected = (file: File | null) => {
		if (file) {
			form.setValues({
				title: form.values.title || file.name,
				slug: form.values.slug || StringUtils.slugifyForUrl(file.name.replace(/\.[^/.]+$/, '')),
				url: StringUtils.slugifyForFilesystem(file.name),
				filename: StringUtils.slugifyForFilesystem(file.name),
				resourceTypeId: form.values.resourceTypeId,
			});
		} else {
			form.setValues({
				url: '',
				filename: '',
			});
		}
		setFileToUpload(file);
	};

	const handleSubmit = async (values: AddResourceForm) => {
		if (!fileToUpload) {
			return;
		}

		const loader = addLoader();

		try {
			const resourceId = uuid();
			const filename = `resources/${resourceId}/${Date.now()}/${fileToUpload.name}`;

			const uploadRequest = s3ObjectStorageService.uploadFile(filename, fileToUpload);
			await uploadRequest.response;
			const url = s3ObjectStorageService.getRedirectUrl(filename);

			const createdResource = await resourceService.create({
				id: resourceId,
				title: values.title,
				slug: values.slug,
				filename,
				url,
				resourceType: values.resourceTypeId
					? {
							connect: {
								id: values.resourceTypeId,
							},
					  }
					: undefined,
				...((categoryId || values.categoryId) && {
					resourceCategories: {
						create: [
							{
								category: {
									connect: {
										id: categoryId || values.categoryId,
									},
								},
							},
						],
					},
				}),
			});
			onCreated(createdResource);
			NotificationUtils.showSuccess('Successfully created resource', createdResource.title);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create resource');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'title');

	const resourceTypeData = resourceTypes.map((resourceType) => ({
		value: resourceType.id,
		label: resourceType.name,
	}));

	const categoryData = (categories ?? []).map((category) => ({
		value: category.item.id,
		label: category.getName(),
	}));

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Group align="flex-end" justify="flex-end">
						<TextInput
							label="Upload file"
							{...form.getInputProps('filename')}
							withAsterisk
							disabled
							style={{ flexGrow: 1 }}
						/>
						<FileButton onChange={handleFileSelected}>
							{(props) => (
								<Tooltip label="Click to upload file">
									<Button {...props}>
										<IconFilePlus />
									</Button>
								</Tooltip>
							)}
						</FileButton>
					</Group>
					<TextInput
						{...slugComputedFromProps}
						label="Title"
						description="The title of the resource. Forms the basis of the slug."
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Select
						{...form.getInputProps('resourceTypeId')}
						data={resourceTypeData}
						label="Resource type"
						placeholder={isLoading ? 'Loading...' : '--- Please select ---'}
						disabled={isLoading}
					/>
					<Select
						{...form.getInputProps('categoryId')}
						data={categoryData}
						label="Initial category"
						placeholder={isLoadingCategories ? 'Loading...' : '--- Please select ---'}
						nothingFoundMessage="No search results"
						disabled={isLoading || isLoadingCategories || categoryId !== undefined}
						searchable
					/>
					<Group justify="flex-end" mt="md">
						<Button
							type="button"
							variant="subtle"
							onClick={handleCancel}
							disabled={isLoading || isLoadingCategories}
						>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading || isLoadingCategories}>
							Add resource {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddResourceForm = Pick<ResourceSingleFragment, 'title' | 'slug' | 'url' | 'filename' | 'resourceTypeId'> & {
	categoryId: string;
};
