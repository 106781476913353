import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import TemplateTypesList from '../../components/templates/TemplateTypesList';

export default function TemplatesPage() {
	return (
		<BackendPage title="Templates">
			<TemplateTypesList />
		</BackendPage>
	);
}
